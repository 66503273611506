import { LOCAL, DEVMAJ, MAJSTG, PRD } from './environments';

export default {
  [LOCAL]: {
    api: 'https://gateway.sunrundev.com/local-template-cms',
    hybridAuth: 'https://gateway.sunrundev.com/local-hybrid-auth',
    ui: 'https://local-template-cms.sunrundev.com:8080',
  },
  [DEVMAJ]: {
    api: 'https://gateway.sunrundev.com/devmaj-template-cms',
    hybridAuth: 'https://gateway.sunrundev.com/devmaj-hybrid-auth',
    ui: 'https://devmaj-template-cms.sunrundev.com',
  },
  [MAJSTG]: {
    api: 'https://gateway.sunrun.com/majstg-template-cms',
    hybridAuth: 'https://gateway.sunrun.com/majstg-hybrid-auth',
    ui: 'https://majstg-template-cms.sunrun.com',
  },
  [PRD]: {
    api: 'https://gateway.sunrun.com/template-cms',
    hybridAuth: 'https://gateway.sunrun.com/hybrid-auth',
    ui: 'https://template-cms.sunrun.com',
  },
};
