import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { UnControlled as CodeMirror } from 'react-codemirror2';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/mode/javascript/javascript.js');

const StyledDiv = styled.div`
  .codemirror-editor, .CodeMirror {
    width: 100%;
    height: 75vh;
  }
`;

const TextEditDialog = ({
  closeDialog,
  initialValue,
  isEditable,
  isObject,
  saveChanges,
  variableName,
}) => {
  const [editedValue, setEditedValue] = useState(initialValue);

  return (
    <Dialog open fullScreen aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{variableName}</DialogTitle>
      <DialogContent dividers>
        <StyledDiv>
          <CodeMirror
            autoCursor={false}
            autoScroll={false}
            className="codemirror-editor"
            value={initialValue}
            options={{
              mode: isObject ? { name: 'javascript', json: true } : null,
              theme: 'material',
              lineNumbers: isObject,
              lineWrapping: !isObject,
              readOnly: !isEditable,
            }}
            onChange={(editor, data, value) => setEditedValue(value)}
          />
        </StyledDiv>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Cancel</Button>
        <Button onClick={() => saveChanges(editedValue)} color="secondary">Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
};

TextEditDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isObject: PropTypes.bool.isRequired,
  saveChanges: PropTypes.func.isRequired,
  variableName: PropTypes.string.isRequired,
};

export default TextEditDialog;
