export const variablesReducer = (variables, action) => {
  switch (action.type) {
    case 'setAllVariables':
      return { ...action.variables };
    case 'setVariable':
      return { ...variables, [action.variableName]: action.value };
    case 'setVariableAttribute':
      return {
        ...variables,
        [action.variableName]: {
          ...variables[action.variableName],
          [action.variableAttribute]: action.value,
        },
      };
    default:
      return variables;
  }
};

export const i18nBundleReducer = (i18nBundle, action) => {
  switch (action.type) {
    case 'setBundle':
      return {
        ...action.i18nBundle,
      };
    case 'setCopyVariable':
      return {
        ...i18nBundle,
        [action.language]: {
          ...i18nBundle[action.language],
          [action.variableName]: action.value || '',
        },
      };
    case 'addNewLanguage': {
      const newLanguageObj = {};
      Object.keys(i18nBundle.en).forEach((key) => {
        newLanguageObj[key] = '';
      });
      return {
        ...i18nBundle,
        [action.newLanguage]: newLanguageObj,
      };
    }
    case 'removeLanguage': {
      const updatedI81nBundle = { ...i18nBundle };
      delete updatedI81nBundle[action.language];
      return updatedI81nBundle;
    }
    default:
      return i18nBundle;
  }
};

export const templateDataReducer = (templateData, action) => {
  switch (action.type) {
    case 'setVariable':
      return { ...templateData, [action.variableName]: action.value };
    case 'setCopyVariable':
      return {
        ...templateData,
        i18nBundle: {
          ...templateData.i18nBundle,
          [action.variableName]: action.value,
        },
      };
    case 'setI18nBundle':
      return {
        ...templateData,
        i18nBundle: {
          ...action.i18nBundle,
        },
      };
    default:
      return templateData;
  }
};
