import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const GenericDialog = ({
  actionClick,
  actionText,
  cancelText,
  closeDialog,
  message,
  title,
}) => (
  <Dialog open fullWidth aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent dividers>
      {message}
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="primary">{cancelText}</Button>
      {!!actionClick && <Button onClick={actionClick} color="secondary">{actionText}</Button>}
    </DialogActions>
  </Dialog>
);

GenericDialog.defaultProps = {
  actionClick: null,
  actionText: '',
  cancelText: 'Close',
};

GenericDialog.propTypes = {
  actionClick: PropTypes.func,
  actionText: PropTypes.string,
  cancelText: PropTypes.string,
  closeDialog: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default GenericDialog;
