import React from 'react';
import styled from '@emotion/styled';
import { darkBlue, darkGray } from '../../styles/colors';

const StyledHeader = styled.div`
  padding: 0 20px;
  border-bottom: 1px solid ${darkGray}
`;
const StyledH1 = styled.h1`
  color: ${darkBlue};
`;

const Header = () => (
  <StyledHeader>
    <StyledH1>Template CMS</StyledH1>
  </StyledHeader>
);

export default Header;
