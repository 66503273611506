import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { withStyles } from '@material-ui/core/styles';
import { imageTypeMap } from '../../constants/mimeTypes';
import styles from '../../styles/appStyles';
import { isImageVariable } from '../../utils/variablesHelper';

const VariableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const VariableValueDiv = styled.div`
  width: 350px;
  padding: 16px 0 0 20px;
  display: inline-block;
`;

const VariablesEditDialog = ({
  classes,
  dispatchTemplateData,
  dispatchVariables,
  images,
  isEditable,
  setErrorMessage,
  setImages,
  setShowVariables,
  setVariableAttribute,
  textEditDialogClick,
  textEditDialogData,
  variables,
}) => {
  const setImageVariableValue = (variableName, imageFile) => {
    const imageExtension = imageTypeMap[imageFile.type];
    if (!imageExtension) {
      setErrorMessage('Invalid image type. Please select another image.');
      return;
    }
    const fileReader = new FileReader();
    const variable = variables[variableName];
    const filename = `${variableName}.${imageExtension}`;
    const newImage = {
      key: variableName,
      filename,
      mimeType: imageFile.type,
      type: variable.type,
      isNew: true,
    };

    fileReader.addEventListener('load', () => {
      newImage.base64String = fileReader.result;
      if (images.some(image => image.filename === filename)) {
        setImages(images.map(image => (image.filename === filename ? newImage : image)));
      } else {
        setImages([...images, newImage]);
      }
      dispatchVariables({ type: 'setVariableAttribute', variableName, variableAttribute: 'value', value: filename });
      dispatchTemplateData({ type: 'setVariable', variableName, value: newImage.base64String });
    }, false);
    fileReader.readAsDataURL(imageFile);
  };

  const variableNames = (variables && Object.keys(variables)) || [];

  return (
    <Dialog fullWidth fullScreen open aria-labelledby="variables-dialog-title">
      <DialogTitle id="variables-dialog-title">Variables</DialogTitle>
      <DialogContent dividers>
        <VariableContainer>
          {variableNames.map(variableName => (
            <div key={`${variableName}-var-div`}>
              <FormControl>
                <InputLabel shrink>
                  {variableName}
                </InputLabel>
                <Select
                  labelId={`${variableName}-selectId`}
                  id={`${variableName}-selectId`}
                  defaultValue={variables[variableName].type}
                  onChange={event => setVariableAttribute(variableName, 'type', event.target.value)}
                  style={{ minWidth: 200 }}
                >
                  <MenuItem value="string">Text</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="object">JSON</MenuItem>
                  <MenuItem value="boolean">True/False</MenuItem>
                  <MenuItem value="staticImage">Static Image</MenuItem>
                  <MenuItem value="dynamicImage">Dynamic Image</MenuItem>
                </Select>
                <FormHelperText>Variable Type</FormHelperText>
              </FormControl>
              {variables[variableName].type === 'string' && (
                <VariableValueDiv>
                  <FormControl fullWidth>
                    <TextField
                      label={variableName}
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={variables[variableName].value}
                      onChange={event => setVariableAttribute(variableName, 'value', event.target.value)}
                    />
                    <FormHelperText style={{ textAlign: 'right' }}>
                      <Button
                        color="secondary"
                        className={classes.smallButton}
                        style={{ marginTop: -3, marginRight: -5 }}
                        onClick={() => textEditDialogClick(variableName)}
                      >
                        Expand
                      </Button>
                    </FormHelperText>
                  </FormControl>
                </VariableValueDiv>
              )}
              {variables[variableName].type === 'number' && (
                <VariableValueDiv>
                  <TextField
                    label={variableName}
                    size="small"
                    variant="outlined"
                    fullWidth
                    defaultValue={variables[variableName].value}
                    onChange={event => setVariableAttribute(variableName, 'value', event.target.value)}
                  />
                </VariableValueDiv>
              )}
              {variables[variableName].type === 'object' && !textEditDialogData.isShowing && (
                <VariableValueDiv>
                  <FormControl fullWidth>
                    <TextField
                      label={variableName}
                      size="small"
                      variant="outlined"
                      fullWidth
                      defaultValue={JSON.stringify(variables[variableName].value)}
                      onChange={event => setVariableAttribute(variableName, 'value', event.target.value)}
                    />
                    <FormHelperText style={{ textAlign: 'right' }}>
                      <Button
                        color="secondary"
                        className={classes.smallButton}
                        style={{ marginTop: -3, marginRight: -5 }}
                        onClick={() => textEditDialogClick(variableName, false, null, true)}
                      >
                        Expand
                      </Button>
                    </FormHelperText>
                  </FormControl>
                </VariableValueDiv>
              )}
              {variables[variableName].type === 'boolean' && (
                <VariableValueDiv>
                  <Select
                    labelId={`${variableName}-bool-value`}
                    id={`${variableName}-bool-value`}
                    defaultValue={variables[variableName].value}
                    onChange={event => setVariableAttribute(variableName, 'value', event.target.value)}
                    fullWidth
                  >
                    <MenuItem value>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </VariableValueDiv>
              )}
              {isImageVariable(variables[variableName]) && (
                <VariableValueDiv>
                  <FormControl>
                    <input
                      accept="image/*"
                      id={`image-upload-input-${variableName}`}
                      disabled={!isEditable && variables[variableName].type === 'staticImage'}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={event => setImageVariableValue(variableName, event.target.files[0])}
                    />
                    <label htmlFor={`image-upload-input-${variableName}`}>
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                    {!!variables[variableName].value && <FormHelperText>{variables[variableName].value}</FormHelperText>}
                  </FormControl>
                </VariableValueDiv>
              )}
            </div>
          ))}
        </VariableContainer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setShowVariables(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

VariablesEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatchTemplateData: PropTypes.func.isRequired,
  dispatchVariables: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditable: PropTypes.bool.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  setShowVariables: PropTypes.func.isRequired,
  setVariableAttribute: PropTypes.func.isRequired,
  textEditDialogClick: PropTypes.func.isRequired,
  textEditDialogData: PropTypes.shape({
    isShowing: PropTypes.bool.isRequired,
  }).isRequired,
  variables: PropTypes.object.isRequired,
};

export default withStyles(styles)(VariablesEditDialog);
