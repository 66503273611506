import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  LinearProgress,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import styled from '@emotion/styled';

import GenericDialog from '../general/GenericDialog';
import rollbar from '../../utils/rollbar';
import getEnv from '../../utils/getEnv';
import BASE_URLS from '../../constants/baseUrls';
import NewTemplateDialog from './NewTemplateDialog';

const ListHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const StyledList = styled.div`
  padding: 20px;
`;

const List = () => {
  const history = useHistory();
  const env = getEnv();
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [isNewTemplateDialogOpen, setNewTemplateDialogOpen] = useState(false);
  const [templateSearch, setTemplateSearch] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  useEffect(() => {
    const getTemplates = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${BASE_URLS[env].api}/templates`);
        setTemplates(data);
        setFilteredTemplates(data);
        setLoading(false);
      } catch (err) {
        rollbar.error(err);
        setLoading(false);
        setErrorMessage(err.message);
      }
    };
    getTemplates();
  }, []);

  const filterTemplates = (searchTerm) => {
    setTemplateSearch(searchTerm);
    setFilteredTemplates(templates.filter(({ templateName }) => templateName.toLowerCase().includes(searchTerm.toLowerCase())));
  };

  const onNewTemplateClick = () => {
    setNewTemplateDialogOpen(true);
  };

  const createTemplate = async (finalTemplate) => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${BASE_URLS[env].api}/templates`, finalTemplate);
      const newTemplates = [...templates, data];
      setTemplates(newTemplates);
      setFilteredTemplates(newTemplates);
      setTemplateSearch('');
      setNewTemplateDialogOpen(false);
      setLoading(false);
    } catch (err) {
      rollbar.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <StyledList>
      <ListHeader>
        <TextField
          id="filter-templates"
          label="Filter templates"
          size="small"
          type="search"
          variant="outlined"
          value={templateSearch}
          onChange={event => filterTemplates(event.target.value)}
        />
        <Button variant="contained" color="primary" onClick={onNewTemplateClick}>New Template</Button>
      </ListHeader>
      <div className="list">
        {(!templates || !templates.length) &&
          <div>No templates have been created yet. Create one by clicking the &quot;New Template&quot; button!</div>
        }
        {(!!templates && templates.length > 0 && !filteredTemplates.length) &&
          <div>There are no templates that match your search criteria.</div>
        }
        {!!filteredTemplates && filteredTemplates.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Published</TableCell>
                  <TableCell align="right">Created By</TableCell>
                  <TableCell align="right">Created On</TableCell>
                  <TableCell align="right">Updated By</TableCell>
                  <TableCell align="right">Updated On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTemplates.map(template => (
                  <TableRow hover key={template.templateName} onClick={() => history.push(`/templates/${template.templateKey}`)}>
                    <TableCell component="th" scope="row">
                      {template.templateName}
                    </TableCell>
                    <TableCell align="right">{template.isPublished ? 'Yes' : 'No'}</TableCell>
                    <TableCell align="right">{template.createdBy}</TableCell>
                    <TableCell align="right">{new Date(template.createdAt).toLocaleString()}</TableCell>
                    <TableCell align="right">{template.updatedBy}</TableCell>
                    <TableCell align="right">{new Date(template.updatedAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {isNewTemplateDialogOpen && (
        <NewTemplateDialog
          closeDialog={() => setNewTemplateDialogOpen(false)}
          createTemplate={createTemplate}
        />
      )}
      {!!errorMessage &&
        <GenericDialog message={errorMessage} title="Error" closeDialog={() => setErrorMessage(null)} />
      }
    </StyledList>
  );
};

export default List;
