import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import rollbar from './utils/rollbar';
import Header from './components/general/Header';
import List from './components/list/List';
import Edit from './components/edit/Edit';

const InvalidRoute = () => {
  const location = useLocation();
  useEffect(() => {
    rollbar.warn(`A user tried to access an invalid route: ${location.pathname}`);
  }, [location]);

  return <Redirect to="/" />;
};

const App = () => (
  <div>
    <Header />
    <Switch>
      <Route path="/" exact>
        <List />
      </Route>
      <Route
        path="/templates/:templateKey"
        exact
        render={props => <Edit templateKey={props.match.params.templateKey} />} // eslint-disable-line react/prop-types
      />
      <Route>
        <InvalidRoute />
      </Route>
    </Switch>
  </div>
);

export default hot(App);
