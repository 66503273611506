import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { StylesProvider, createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback, withOktaAuth } from '@okta/okta-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Helmet } from 'react-helmet';

import App from './App';
import ErrorBoundary from './components/general/ErrorBoundary';
import getTheme from './utils/getTheme';
import wrapAxios from './utils/wrapAxios';
import getEnv from './utils/getEnv';
import OKTA_CONFIG from './constants/okta';
import BASE_URLS from './constants/baseUrls';
import { trackUser as rollbarTrackUser } from './utils/rollbar';

import 'typeface-roboto';

const theme = createMuiTheme(getTheme());

const UserGate = ({ authService }) => {
  const [trackingComplete, setTrackingComplete] = useState(false);
  useEffect(() => {
    wrapAxios(authService);
    authService.getUser().then((user) => {
      if (!user) {
        localStorage.removeItem('hybridToken');
        authService.logout();
      } else {
        rollbarTrackUser(user);
        setTrackingComplete(true);
      }
    });
  }, [authService]);

  if (!trackingComplete) return <LinearProgress />;

  return <App />;
};

UserGate.propTypes = {
  authService: PropTypes.shape({
    getUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
};

const WrappedApp = () => {
  const env = getEnv();
  return (
    <ErrorBoundary>
      <Helmet>
        <title>Template CMS</title>
      </Helmet>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Security
                issuer={`https://sunrun.okta.com/oauth2/${OKTA_CONFIG[env].authServer}`}
                client_id={OKTA_CONFIG[env].clientId}
                redirect_uri={`${BASE_URLS[env].ui}/callback`}
                pkce={false}
              >
                <Switch>
                  <Route path="/callback" component={LoginCallback} />
                  <SecureRoute component={withOktaAuth(UserGate)} />
                </Switch>
              </Security>
            </Router>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  );
};

ReactDOM.render(<WrappedApp />, global.document.getElementById('root'));
