import { primary, secondary } from '../styles/colors';

const getTheme = () => ({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
});

export default getTheme;
