import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { LinearProgress } from '@material-ui/core';

import { extractEjsGlobals } from '../../lib/ejsParser';

import {
  cloneTemplateVersion,
  fetchS3Resources,
  fetchS3ResourceUrls,
  fetchVersions,
  publishTemplateVersion,
  saveTemplateVersion,
  testTemplateVersion,
} from '../../services/templateService';

import rollbar from '../../utils/rollbar';
import { isImageVariable } from '../../utils/variablesHelper';
import {
  variablesReducer, i18nBundleReducer, templateDataReducer,
} from '../../reducers/EditReducers';
import GenericDialog from '../general/GenericDialog';
import AddLanguageDialog from './AddLanguageDialog';
import EditHeader from './EditHeader';
import EjsEdit from './EjsEdit';
import PdfTestOutputDialog from './PdfTestOutputDialog';
import VariablesCopyAndFonts from './VariablesCopyAndFonts';

const Edit = ({ templateKey }) => {
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [versions, setVersions] = useState([]);
  const [template, setTemplate] = useState({});
  const [ejsEditSection, setEjsEditSection] = useState('template');
  const [originalTemplateEjs, setOriginalTemplateEjs] = useState('');
  const [originalHeaderEjs, setOriginalHeaderEjs] = useState('');
  const [originalFooterEjs, setOriginalFooterEjs] = useState('');
  const [originalVariables, setOriginalVariables] = useState(null);
  const [originalI18nBundle, setOriginalI18nBundle] = useState(null);
  const [editedTemplateEjs, setEditedTemplateEjs] = useState('');
  const [editedHeaderEjs, setEditedHeaderEjs] = useState('');
  const [editedFooterEjs, setEditedFooterEjs] = useState('');
  const [allParsedEjsVars, setAllParsedEjsVars] = useState([]);
  const [languages, setLanguages] = useState(['en']);
  const [showAddLanguageDialog, setShowAddLanguageDialog] = useState(false);
  const [outputLanguage, setOutputLanguage] = useState('en');
  const [images, setImages] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [showPdfTestOutputDialog, setShowPdfTestOutputDialog] = useState(false);
  const [pdfTestOutput, setPdfTestOutput] = useState(null);

  const [variables, dispatchVariables] = useReducer(variablesReducer, {});
  const [i18nBundle, dispatchI18nBundle] = useReducer(i18nBundleReducer, { en: {} });
  const [templateData, dispatchTemplateData] = useReducer(templateDataReducer, {});

  const parseVariablesFromEjsTemplate = (ejsTemplate, ejsHeader, ejsFooter) => {
    function hasDifferentEjsVars(newEjsVars) {
      return newEjsVars.length !== allParsedEjsVars.length ||
        !newEjsVars.every(variableName => allParsedEjsVars.includes(variableName));
    }

    let copyVariableNames = [];
    let otherVariableNames = [];
    const ejsTemplateVars = extractEjsGlobals(ejsTemplate);
    const ejsHeaderVars = extractEjsGlobals(ejsHeader);
    const ejsFooterVars = extractEjsGlobals(ejsFooter);
    const ejsVars = [...new Set([...ejsTemplateVars, ...ejsHeaderVars, ...ejsFooterVars])];

    if (ejsVars && hasDifferentEjsVars(ejsVars)) {
      setAllParsedEjsVars(ejsVars);
      copyVariableNames = ejsVars
        .filter(ejsVar => ejsVar.indexOf('i18nBundle') === 0)
        .map(ejsCopyVar => ejsCopyVar.split('.')[1]);

      otherVariableNames = ejsVars.filter(ejsVar => ejsVar.indexOf('i18nBundle') === -1 && ejsVar !== 'fonts');
    }

    return {
      copyVariableNames,
      otherVariableNames,
    };
  };

  const onTemplateEjsEdit = async (updatedEjs, initialI18nBundle, initialVariables = {}, initialImages = [], initialHeader, initialFooter) => {
    const isInitialLoad = (!!initialHeader || initialHeader === '') && (!!initialFooter || initialFooter === '');
    const templateToUse = ejsEditSection === 'template' || isInitialLoad ? updatedEjs : editedTemplateEjs;
    const headerToUse = isInitialLoad ? initialHeader : ejsEditSection === 'header' ? updatedEjs : editedHeaderEjs;
    const footerToUse = isInitialLoad ? initialFooter : ejsEditSection === 'footer' ? updatedEjs : editedFooterEjs;
    const { copyVariableNames, otherVariableNames } = parseVariablesFromEjsTemplate(templateToUse, headerToUse, footerToUse);

    if (copyVariableNames.length) {
      copyVariableNames.forEach((variableName) => {
        const supportedLanguages = initialI18nBundle ? Object.keys(initialI18nBundle) : Object.keys(i18nBundle);
        supportedLanguages.forEach((language) => {
          const initialValue = initialI18nBundle && initialI18nBundle[language] && initialI18nBundle[language][variableName];
          const currentValue = i18nBundle && i18nBundle[language] && i18nBundle[language][variableName];
          if (!initialValue && initialValue !== '' && !currentValue) {
            dispatchI18nBundle({ type: 'setCopyVariable', language, variableName, value: '' });
          }
          if (language === outputLanguage) {
            const templateDataValue = currentValue || initialValue;
            dispatchTemplateData({ type: 'setCopyVariable', variableName, value: templateDataValue });
          }
        });
      });
    }

    if (otherVariableNames.length) {
      otherVariableNames.forEach((variableName) => {
        if (initialVariables[variableName] || !variables[variableName]) {
          const variableValue = initialVariables[variableName] || { type: 'string', value: '' };
          dispatchVariables({ type: 'setVariable', variableName, value: variableValue });
          let templateDataValue = variableValue.value;
          if (isImageVariable(variableValue) && variableValue.value) {
            const image = images.find(img => img.filename === variableValue.value) ||
              initialImages.find(img => img.filename === variableValue.value);
            templateDataValue = image.base64String;
          }
          dispatchTemplateData({ type: 'setVariable', variableName, value: templateDataValue });
        }
      });
    }

    if (ejsEditSection === 'header' || isInitialLoad) {
      setEditedHeaderEjs(headerToUse);
    }
    if (ejsEditSection === 'footer' || isInitialLoad) {
      setEditedFooterEjs(footerToUse);
    }
    if (ejsEditSection === 'template' || isInitialLoad) {
      setEditedTemplateEjs(templateToUse);
    }
  };

  const fetchS3ResourcesAndSetTemplate = async (templateVersion, s3Urls) => {
    const s3Resources = await fetchS3Resources(templateVersion, s3Urls);

    dispatchI18nBundle({ type: 'setBundle', i18nBundle: s3Resources.i18nBundle });
    setOriginalI18nBundle(s3Resources.i18nBundle);
    dispatchVariables({ type: 'setAllVariables', variables: s3Resources.variables });
    setOriginalVariables(s3Resources.variables);

    setImages(s3Resources.images);
    setFonts(s3Resources.fonts);

    if (templateVersion.hasI18nBundle) {
      setLanguages(Object.keys(s3Resources.i18nBundle));
    }

    setTemplate(templateVersion);
    setOriginalTemplateEjs(s3Resources.templateEjs);
    setOriginalHeaderEjs(s3Resources.headerEjs);
    setOriginalFooterEjs(s3Resources.footerEjs);
    onTemplateEjsEdit(s3Resources.templateEjs, s3Resources.i18nBundle,
      s3Resources.variables, s3Resources.images, s3Resources.headerEjs, s3Resources.footerEjs);
  };

  const getVersions = async (versionToLoad) => {
    try {
      setLoading(true);
      const versionsResponse = await fetchVersions(templateKey);
      setVersions(versionsResponse);
      const templateVersion = versionToLoad ? versionsResponse.find(version => version.version === versionToLoad) : versionsResponse[0];
      await fetchS3ResourcesAndSetTemplate(templateVersion, templateVersion.s3Urls);
      setLoading(false);
    } catch (err) {
      rollbar.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  const switchVersion = async (templateVersion) => {
    try {
      setLoading(true);
      const s3Urls = await fetchS3ResourceUrls(templateVersion, 'GET');
      await fetchS3ResourcesAndSetTemplate(templateVersion, s3Urls);
      setLoading(false);
    } catch (err) {
      rollbar.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  const saveVersion = async () => {
    try {
      setLoading(true);
      const updatedTemplate = await saveTemplateVersion(template, editedTemplateEjs,
        editedHeaderEjs, editedFooterEjs, languages, i18nBundle, variables, images, fonts);
      setImages(images.map(({ key, filename, mimeType, type, base64String }) => ({ key, filename, mimeType, type, base64String })));
      setFonts(fonts.map(({ key, filename, family, base64String }) => ({ key, filename, family, base64String })));
      setTemplate(updatedTemplate);
      setOriginalTemplateEjs(editedTemplateEjs);
      setOriginalHeaderEjs(editedHeaderEjs);
      setOriginalFooterEjs(editedFooterEjs);
      setOriginalI18nBundle(i18nBundle);
      setOriginalVariables(variables);
      setLoading(false);
    } catch (err) {
      rollbar.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  const cloneVersion = async () => {
    try {
      setLoading(true);
      await cloneTemplateVersion(template);
      await getVersions();
    } catch (err) {
      rollbar.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  const testVersion = async () => {
    try {
      setLoading(true);
      setPdfTestOutput(null);
      const testOutput = await testTemplateVersion(template);
      setPdfTestOutput(testOutput);
      setShowPdfTestOutputDialog(true);
      setLoading(false);
    } catch (err) {
      rollbar.error(err);
      setPdfTestOutput(null);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  const publishVersion = async () => {
    try {
      setLoading(true);
      await publishTemplateVersion(template);
      await getVersions(template.version);
      setLoading(false);
    } catch (err) {
      rollbar.error(err);
      setLoading(false);
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    getVersions();
  }, []);

  const addLanguage = (newLanguage) => {
    setShowAddLanguageDialog(false);
    dispatchI18nBundle({ type: 'addNewLanguage', newLanguage });
    setLanguages([...languages, newLanguage]);
  };

  const removeLanguage = (language) => {
    dispatchI18nBundle({ type: 'removeLanguage', language });
    setLanguages(languages.filter(lang => lang !== language));
  };

  const changeOutputLanguage = (language) => {
    setOutputLanguage(language);
    dispatchTemplateData({ type: 'setI18nBundle', i18nBundle: i18nBundle[language] });
  };

  const compileFonts = () => {
    let fontStr = '';
    fonts.forEach((font) => {
      fontStr += `@font-face {
        font-family: '${font.family}';
        font-style: normal;
        font-weight: normal;
        src: url(${font.base64String});}\n`;
    });
    dispatchTemplateData({ type: 'setVariable', variableName: 'fonts', value: fontStr });
  };

  useEffect(() => {
    compileFonts();
  }, [fonts]);

  if (isLoading) {
    return <LinearProgress />;
  }

  const hasChanges =
    originalTemplateEjs !== editedTemplateEjs ||
    originalHeaderEjs !== editedHeaderEjs ||
    originalFooterEjs !== editedFooterEjs ||
    JSON.stringify(originalI18nBundle) !== JSON.stringify(i18nBundle) ||
    JSON.stringify(originalVariables) !== JSON.stringify(variables) ||
    (!!fonts && fonts.some(font => font.isNew));

  return (
    <div>
      <EditHeader
        cloneVersion={cloneVersion}
        hasChanges={hasChanges}
        publishVersion={publishVersion}
        saveVersion={saveVersion}
        switchVersion={switchVersion}
        template={template}
        testVersion={testVersion}
        versions={versions}
      />
      <VariablesCopyAndFonts
        addLanguage={addLanguage}
        dispatchI18nBundle={dispatchI18nBundle}
        dispatchTemplateData={dispatchTemplateData}
        dispatchVariables={dispatchVariables}
        fonts={fonts}
        i18nBundle={i18nBundle}
        images={images}
        isEditable={!template.isPublished}
        languages={languages}
        outputLanguage={outputLanguage}
        removeLanguage={removeLanguage}
        setErrorMessage={setErrorMessage}
        setFonts={setFonts}
        setImages={setImages}
        setShowAddLanguageDialog={setShowAddLanguageDialog}
        variables={variables}
      />
      <EjsEdit
        changeOutputLanguage={changeOutputLanguage}
        ejsEditSection={ejsEditSection}
        languages={languages}
        isEditable={!template.isPublished}
        onTemplateEjsEdit={onTemplateEjsEdit}
        setEjsEditSection={setEjsEditSection}
        templateData={templateData}
        templateEjs={ejsEditSection === 'header' ? editedHeaderEjs : ejsEditSection === 'footer' ? editedFooterEjs : editedTemplateEjs}
      />
      {showAddLanguageDialog && (
        <AddLanguageDialog
          addedLanguages={languages}
          addLanguage={addLanguage}
          closeDialog={() => setShowAddLanguageDialog(false)}
        />
      )}
      {showPdfTestOutputDialog && !!pdfTestOutput &&
        <PdfTestOutputDialog pdfTestOutput={pdfTestOutput} closeDialog={() => setShowPdfTestOutputDialog(false)} />
      }
      {!!errorMessage &&
        <GenericDialog message={errorMessage} title="Error" closeDialog={() => setErrorMessage(null)} />
      }
    </div>
  );
};

Edit.propTypes = {
  templateKey: PropTypes.string.isRequired,
};

export default Edit;
