import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CopyEditDialog from './CopyEditDialog';
import FontsDialog from './FontsDialog';
import VariablesEditDialog from './VariablesEditDialog';
import TextEditDialog from './TextEditDialog';

const VariablesCopyAndFonts = ({
  dispatchI18nBundle,
  dispatchTemplateData,
  dispatchVariables,
  fonts,
  i18nBundle,
  images,
  isEditable,
  languages,
  outputLanguage,
  removeLanguage,
  setErrorMessage,
  setFonts,
  setImages,
  setShowAddLanguageDialog,
  variables,
}) => {
  const [showCopy, setShowCopy] = useState(false);
  const [showVariables, setShowVariables] = useState(false);
  const [showFonts, setShowFonts] = useState(false);
  const [textEditDialogData, setTextEditDialogData] = useState({ isShowing: false });

  const setCopy = (variableName, language, value) => {
    dispatchI18nBundle({ type: 'setCopyVariable', variableName, language, value });
    if (language === outputLanguage) {
      dispatchTemplateData({ type: 'setCopyVariable', variableName, value });
    }
  };

  const setVariableAttribute = (variableName, attributeKey, attributeValue) => {
    let evaluatedValue = attributeKey === 'value' ? attributeValue : variables[variableName].value;
    const variableType = attributeKey === 'type' ? attributeValue : variables[variableName].type;
    if (variableType === 'object') {
      try {
        evaluatedValue = JSON.parse(evaluatedValue);
      } catch (err) {
        evaluatedValue = {};
      }
    } else if (variableType === 'number') {
      evaluatedValue = parseFloat(evaluatedValue);
    }

    dispatchTemplateData({ type: 'setVariable', variableName, value: evaluatedValue });

    // If we are changing the type and already have a value we need to make sure we convert the value
    if (attributeKey === 'type' && variables[variableName].value) {
      const updatedVariable = { ...variables[variableName], type: attributeValue, value: evaluatedValue };
      dispatchVariables({ type: 'setVariable', variableName, value: updatedVariable });
    } else {
      const finalAttributeValue = attributeKey === 'value' ? evaluatedValue : attributeValue;
      dispatchVariables({ type: 'setVariableAttribute', variableName, variableAttribute: attributeKey, value: finalAttributeValue });
    }
  };

  const textEditDialogClick = (variableName, isCopy, language, isObject) => {
    const initialValue = isCopy ? i18nBundle[language][variableName] :
      isObject ? JSON.stringify(variables[variableName].value, null, 2) :
        variables[variableName].value;
    setTextEditDialogData({ isShowing: true, variableName, initialValue, isCopy, language, isObject });
  };

  const textEditDialogSaveClick = (value) => {
    const { variableName, isCopy, language } = textEditDialogData;
    if (isCopy) {
      setCopy(variableName, language, value);
    } else {
      setVariableAttribute(variableName, 'value', value);
    }
    setTextEditDialogData({ isShowing: false });
  };

  const i18nBundleKeys = (i18nBundle && i18nBundle.en && Object.keys(i18nBundle.en)) || [];
  const variableNames = (variables && Object.keys(variables)) || [];

  return (
    <div>
      {variableNames.length > 0 && <Button color="primary" onClick={() => setShowVariables(true)} style={{ marginLeft: 5 }}><AddIcon /> Variables</Button>}
      {i18nBundleKeys.length > 0 && <Button color="primary" onClick={() => setShowCopy(true)} style={{ marginLeft: 5 }}><AddIcon /> Copy</Button>}
      <Button color="primary" onClick={() => setShowFonts(true)} style={{ marginLeft: 5 }}><AddIcon /> Fonts</Button>
      {showCopy && (
        <CopyEditDialog
          i18nBundle={i18nBundle}
          isEditable={isEditable}
          languages={languages}
          removeLanguage={removeLanguage}
          setCopy={setCopy}
          setShowAddLanguageDialog={setShowAddLanguageDialog}
          setShowCopy={setShowCopy}
          textEditDialogClick={textEditDialogClick}
        />
      )}
      {showVariables && (
        <VariablesEditDialog
          dispatchTemplateData={dispatchTemplateData}
          dispatchVariables={dispatchVariables}
          images={images}
          isEditable={isEditable}
          setErrorMessage={setErrorMessage}
          setImages={setImages}
          setShowVariables={setShowVariables}
          setVariableAttribute={setVariableAttribute}
          textEditDialogClick={textEditDialogClick}
          textEditDialogData={textEditDialogData}
          variables={variables}
        />
      )}
      {showFonts && (
        <FontsDialog
          fonts={fonts}
          isEditable={isEditable}
          setFonts={setFonts}
          setShowFonts={setShowFonts}
        />
      )}
      {textEditDialogData.isShowing && (
        <TextEditDialog
          closeDialog={() => setTextEditDialogData({ isShowing: false })}
          initialValue={textEditDialogData.initialValue}
          isEditable={isEditable}
          isObject={textEditDialogData.isObject}
          saveChanges={textEditDialogSaveClick}
          variableName={textEditDialogData.variableName}
        />
      )}
    </div>
  );
};

VariablesCopyAndFonts.defaultProps = {
  i18nBundle: null,
  outputLanguage: null,
  variables: null,
};

VariablesCopyAndFonts.propTypes = {
  dispatchI18nBundle: PropTypes.func.isRequired,
  dispatchTemplateData: PropTypes.func.isRequired,
  dispatchVariables: PropTypes.func.isRequired,
  fonts: PropTypes.arrayOf(PropTypes.object).isRequired,
  i18nBundle: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditable: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  outputLanguage: PropTypes.string,
  removeLanguage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setFonts: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  setShowAddLanguageDialog: PropTypes.func.isRequired,
  variables: PropTypes.object,
};

export default VariablesCopyAndFonts;
