import axios from 'axios';
import getEnv from '../utils/getEnv';
import BASE_URLS from '../constants/baseUrls';
import OKTA_CONFIG from '../constants/okta';

export const getHybridToken = async () => {
  const existingHybridToken = localStorage.getItem('hybridToken');
  if (existingHybridToken) {
    return existingHybridToken;
  }

  const oktaTokenStorage = localStorage.getItem('okta-token-storage');
  if (!oktaTokenStorage) {
    return undefined;
  }
  const oktaToken = JSON.parse(oktaTokenStorage).accessToken.value;
  const { data: { hybridToken } } = await axios.post(
    `${BASE_URLS[getEnv()].hybridAuth}/hybridTokens`,
    {
      tokenType: 'okta',
      token: oktaToken,
      authServer: OKTA_CONFIG[getEnv()].authServer,
    },
  );

  localStorage.setItem('hybridToken', hybridToken);
  return hybridToken;
};
