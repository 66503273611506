export default {
  transformRequest: [
    (data, headers) => {
      // This transform is necessary due to https://github.com/axios/axios/issues/382
      // More info: https://stackoverflow.com/questions/48597477/signaturedoesnotmatch-when-using-axios-to-put-image-in-digital-ocean-spaces-us
      // eslint-disable-next-line no-param-reassign
      delete headers.put['Content-Type'];
      return data;
    },
  ],
};
