import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import styled from '@emotion/styled';
import {
  Button,
  ButtonGroup,
  MenuItem,
  Select,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { darkGray, errorRed } from '../../styles/colors';
import styles from '../../styles/appStyles';
import languageMap from '../../constants/languages';
import ejs from '../../lib/ejs';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/mode/htmlembedded/htmlembedded.js');

const StyledEdit = styled.div`
  padding: 0 15px;

  .editor-toggle, .editor {
    display: flex;
    justify-content: space-between;
  }

  .editor-options {
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .editor-options-left, .editor-options-right {
      width: 49%;
      display: flex;
      justify-content: space-between;
    }
  }

  .codemirror-editor, iframe, .ejs-error {
    height: 100vh;
    min-width: 49%;
    width: 100%;
    margin: 0 5px;
  }
  .CodeMirror {
    height: 100vh;
  }

  iframe {
    border: 1px solid ${darkGray};
  }

  .ejs-error {
    border: 1px solid ${errorRed};
    color: ${errorRed};
  }
`;

const EjsEdit = ({
  classes,
  ejsEditSection,
  languages,
  isEditable,
  templateData,
  templateEjs,
  changeOutputLanguage,
  onTemplateEjsEdit,
  setEjsEditSection,
}) => {
  const [showEditor, setShowEditor] = useState(true);
  const [showPreview, setShowPreview] = useState(true);

  let evaluatedHtml;
  let ejsError;
  try {
    evaluatedHtml = ejs.render(templateEjs, templateData);
  } catch (err) {
    ejsError = err.message;
  }

  return (
    <StyledEdit>
      <div className="editor-options">
        <div className="editor-options-left">
          <ButtonGroup color="secondary" aria-label="outlined primary button group">
            <Button
              variant={ejsEditSection === 'template' ? 'contained' : 'outlined'}
              onClick={() => setEjsEditSection('template')}
            >
              Template
            </Button>
            <Button
              variant={ejsEditSection === 'header' ? 'contained' : 'outlined'}
              onClick={() => setEjsEditSection('header')}
            >
              Header
            </Button>
            <Button
              variant={ejsEditSection === 'footer' ? 'contained' : 'outlined'}
              onClick={() => setEjsEditSection('footer')}
            >
              Footer
            </Button>
          </ButtonGroup>
          {!showEditor &&
            <Button color="primary" variant="text" className={classes.smallButton} onClick={() => setShowEditor(true)}>+ show editor</Button>
          }
          {showEditor &&
            <Button color="primary" variant="text" className={classes.smallButton} onClick={() => setShowEditor(false)}>- hide editor</Button>
          }
        </div>
        <div className="editor-options-right">
          {!showPreview &&
            <Button color="primary" variant="text" className={classes.smallButton} onClick={() => setShowPreview(true)}>+ show preview</Button>
          }
          {showPreview &&
            <Button color="primary" variant="text" className={classes.smallButton} onClick={() => setShowPreview(false)}>- hide preview</Button>
          }
          {languages.length > 1 && (
            <Select
              labelId="output-language-select"
              id="output-language-select"
              defaultValue="en"
              onChange={event => changeOutputLanguage(event.target.value)}
            >
              {languages.map(language => (
                <MenuItem
                  key={`output-${language}-menu-item`}
                  value={language}
                >
                  {languageMap[language]}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </div>
      <div className="editor">
        {showEditor && (
          <CodeMirror
            autoCursor={false}
            autoScroll={false}
            className="codemirror-editor"
            value={templateEjs}
            options={{
              mode: 'htmlembedded',
              theme: 'material',
              lineNumbers: true,
              readOnly: !isEditable,
            }}
            onChange={(editor, data, value) => onTemplateEjsEdit(value)}
          />
        )}
        {!ejsError && showPreview &&
          <iframe title="evaluated-ejs" sandbox="allow-same-origin" srcDoc={evaluatedHtml} />
        }
        {!!ejsError && showPreview && <div className="ejs-error">{ejsError}</div>}
      </div>
    </StyledEdit>
  );
};

EjsEdit.propTypes = {
  changeOutputLanguage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  ejsEditSection: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTemplateEjsEdit: PropTypes.func.isRequired,
  setEjsEditSection: PropTypes.func.isRequired,
  templateData: PropTypes.shape({}).isRequired,
  templateEjs: PropTypes.string.isRequired,
};

export default withStyles(styles)(EjsEdit);
