import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import languageMap from '../../constants/languages';

const AddLanguageDialog = ({
  addedLanguages,
  addLanguage,
  closeDialog,
}) => {
  const languages = Object.keys(languageMap);
  const defaultLanguage = languages.find(language => !addedLanguages.includes(language));

  const [selectedValue, setSelectedValue] = useState(defaultLanguage);

  return (
    <Dialog open aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Language</DialogTitle>
      <DialogContent>
        <Select
          labelId="add-language-select"
          id="add-language-select"
          defaultValue={defaultLanguage}
          onChange={event => setSelectedValue(event.target.value)}
        >
          {languages.map(language => (
            <MenuItem
              key={`add-${language}-menu-item`}
              value={language}
              disabled={addedLanguages.includes(language)}
            >
              {languageMap[language]}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={() => addLanguage(selectedValue)} color="secondary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddLanguageDialog.propTypes = {
  addedLanguages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  addLanguage: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default AddLanguageDialog;
