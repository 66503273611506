import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import styled from '@emotion/styled';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

const PaginationDiv = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
`;

const PdfTestOutputDialog = ({ pdfTestOutput, closeDialog }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  const onPdfLoad = ({ _pdfInfo: { numPages: numPagesFromPdf } }) => {
    setNumPages(numPagesFromPdf);
  };

  return (
    <Dialog fullWidth fullScreen={!!pdfUrl} open aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">PDF Generation Test Results</DialogTitle>
      <DialogContent dividers>
        {!pdfUrl && pdfTestOutput.map(pdf => (
          <h2>
            {pdf.name}
            <Button color="secondary" onClick={() => setPdfUrl(pdf.url)}>View PDF</Button>
          </h2>
        ))}
        {!!pdfUrl && (
          <Document file={pdfUrl} onLoadSuccess={onPdfLoad}>
            <Page pageNumber={pageNum} />
          </Document>
        )}
      </DialogContent>
      <DialogActions>
        {!!pdfUrl && !!numPages && (
          <PaginationDiv>
            <IconButton
              color="primary"
              onClick={() => setPageNum(pageNum - 1)}
              disabled={pageNum === 1}
              style={{ marginRight: 5 }}
            >
              <ArrowLeftIcon />
            </IconButton>
            <span>Page {pageNum} of {numPages}</span>
            <IconButton
              color="primary"
              onClick={() => setPageNum(pageNum + 1)}
              disabled={pageNum === numPages}
              style={{ marginRight: 5 }}
            >
              <ArrowRightIcon />
            </IconButton>
          </PaginationDiv>
        )}
        {!!pdfUrl && <Button onClick={() => setPdfUrl(null)} color="primary">Back to Results</Button>}
        <Button onClick={closeDialog} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

PdfTestOutputDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  pdfTestOutput: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export default PdfTestOutputDialog;
