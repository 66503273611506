import axios from 'axios';
import getEnv from '../utils/getEnv';
import BASE_URLS from '../constants/baseUrls';
import s3PutConfig from '../constants/s3PutConfig';

const env = getEnv();

export const fetchS3Resources = async (template, s3Urls) => {
  const {
    templateEjsS3Url, headerEjsS3Url, footerEjsS3Url, variablesS3Url, i18nBundleS3Url, imageS3Urls, fontS3Urls,
  } = s3Urls;
  const [
    { data: templateEjs },
    { data: headerEjs },
    { data: footerEjs },
    { data: variables },
    { data: i18nBundle },
    imageData,
    fontData,
  ] = await Promise.all([
    axios.get(templateEjsS3Url),
    template.hasHeader && headerEjsS3Url ? axios.get(headerEjsS3Url) : Promise.resolve({ data: '' }),
    template.hasFooter && footerEjsS3Url ? axios.get(footerEjsS3Url) : Promise.resolve({ data: '' }),
    template.hasVariables && variablesS3Url ? axios.get(variablesS3Url) : Promise.resolve({ data: {} }),
    template.hasI18nBundle && i18nBundleS3Url ? axios.get(i18nBundleS3Url) : Promise.resolve({ data: { en: {} } }),
    Promise.all(imageS3Urls.map(({ s3Url }) => axios.get(s3Url, { responseType: 'arraybuffer' }))),
    Promise.all(fontS3Urls.map(({ s3Url }) => axios.get(s3Url, { responseType: 'arraybuffer' }))),
  ]);

  const images = imageS3Urls.map(({ filename }, index) => {
    const { key, mimeType, type } = template.images.find(image => image.filename === filename);
    const base64String = Buffer.from(imageData[index].data, 'binary').toString('base64');
    return {
      key,
      filename,
      mimeType,
      type,
      base64String: `data:${mimeType};base64,${base64String}`,
    };
  });

  const fonts = fontS3Urls.map(({ filename }, index) => {
    const { key, family, mimeType } = template.fonts.find(font => font.filename === filename);
    return {
      key,
      filename,
      mimeType,
      family,
      base64String: Buffer.from(fontData[index].data, 'binary').toString('base64'),
    };
  });

  return {
    templateEjs,
    headerEjs,
    footerEjs,
    i18nBundle,
    variables,
    images,
    fonts,
  };
};

export const saveTemplateVersion = async (template, templateEjs, headerEjs, footerEjs, languages, i18nBundle, variables, images, fonts) => {
  const { templateKey, version } = template;
  const templateBody = {
    hasI18nBundle: !!i18nBundle && !!i18nBundle.en && Object.keys(i18nBundle.en).length > 0,
    hasVariables: !!variables && Object.keys(variables).length > 0,
    hasHeader: !!headerEjs,
    hasFooter: !!footerEjs,
    languages,
    images: images.map(({ key, filename, isNew, mimeType, type }) => ({ key, filename, isNew, mimeType, type })),
    fonts: fonts.map(({ key, family, filename, isNew, mimeType }) => ({ key, family, filename, isNew, mimeType })),
  };

  const {
    data: {
      updatedTemplate,
      s3Urls: {
        templateEjsS3Url,
        headerEjsS3Url,
        footerEjsS3Url,
        variablesS3Url,
        i18nBundleS3Url,
        imageS3Urls,
        fontS3Urls,
      },
    },
  } = await axios.patch(`${BASE_URLS[env].api}/templates/${templateKey}/versions/${version}`, templateBody);

  await Promise.all([
    axios.put(templateEjsS3Url, Buffer.from(templateEjs), s3PutConfig),
    updatedTemplate.hasHeader ? axios.put(headerEjsS3Url, Buffer.from(headerEjs), s3PutConfig) : Promise.resolve(),
    updatedTemplate.hasFooter ? axios.put(footerEjsS3Url, Buffer.from(footerEjs), s3PutConfig) : Promise.resolve(),
    updatedTemplate.hasVariables ? axios.put(variablesS3Url, Buffer.from(JSON.stringify(variables)), s3PutConfig) : Promise.resolve(),
    updatedTemplate.hasI18nBundle ? axios.put(i18nBundleS3Url, Buffer.from(JSON.stringify(i18nBundle)), s3PutConfig) : Promise.resolve(),
    ...imageS3Urls.map(({ filename, s3Url }) => {
      const imageBase64String = images.find(image => image.filename === filename).base64String;
      return axios.put(s3Url, Buffer.from(imageBase64String.replace(/^data:image\/\w+;base64,/, ''), 'base64'), s3PutConfig);
    }),
    ...fontS3Urls.map(({ filename, s3Url }) => {
      const fontBase64String = fonts.find(font => font.filename === filename).base64String;
      return axios.put(s3Url, Buffer.from(fontBase64String, 'base64'), s3PutConfig);
    }),
  ]);

  return updatedTemplate;
};

export const fetchVersions = async (templateKey) => {
  const { data } = await axios.get(`${BASE_URLS[env].api}/templates/${templateKey}/versions`);
  return data;
};

export const fetchS3ResourceUrls = async (template, method) => {
  const { templateKey, version } = template;
  const { data: s3Urls } = await axios.get(`${BASE_URLS[env].api}/templates/${templateKey}/versions/${version}/urls?method=${method}`);
  return s3Urls;
};

export const cloneTemplateVersion = async (template) => {
  const { templateKey, templateName } = template;
  const cloneBody = {
    templateKey,
    templateName,
  };
  await axios.post(`${BASE_URLS[env].api}/templates`, cloneBody);
};

export const testTemplateVersion = async (template) => {
  const { templateKey, version } = template;
  const { data } = await axios.post(`${BASE_URLS[env].api}/templates/${templateKey}/versions/${version}/test`);
  return data;
};

export const publishTemplateVersion = async (template) => {
  const { templateKey, version } = template;
  await axios.post(`${BASE_URLS[env].api}/templates/${templateKey}/versions/${version}/publish`);
};
