export const imageTypeMap = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/svg+xml': 'svg',
};

export const fontTypeMap = {
  'application/font-woff': 'woff',
  'application/font-woff2': 'woff2',
  'application/vnd.ms-fontobject': 'eot',
  'application/x-font-ttf': 'ttf',
  'application/octet-stream': 'ttf',
  'font/opentype': 'otf',
  'image/svg+xml': 'svg',
};
