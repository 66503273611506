import { darkGray, secondary } from './colors';

const styles = theme => ({
  inactiveBadge: {
    backgroundColor: darkGray,
  },
  smallButton: {
    textTransform: 'none',
    height: 30,
    margin: 5,
  },
  snackbar: {
    backgroundColor: secondary,
    color: '#ffffff',
  },
});

export default styles;
