import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { fontTypeMap } from '../../constants/mimeTypes';

const FontsDialog = ({
  fonts,
  isEditable,
  setFonts,
  setShowFonts,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showNewFontDialog, setShowNewFontDialog] = useState(false);
  const [newFont, setNewFont] = useState({});

  const setNewFontSource = (fontFile) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      const mimeType = fileReader.result.split(':')[1].split(';')[0];
      setNewFont({ ...newFont, base64String: fileReader.result, mimeType });
    }, false);
    fileReader.readAsDataURL(fontFile);
  };

  const saveFont = () => {
    if (!newFont.family || !newFont.base64String) {
      setErrorMessage('Please fill out the font-family and upload a font file');
      return;
    }

    const key = newFont.family.replace(/\s+/g, '-').toLowerCase();
    const fontExtension = fontTypeMap[newFont.mimeType];
    if (!fontExtension) {
      setErrorMessage('Unrecognized mimeType on uploaded font file. Please try another font');
      return;
    }

    const finalNewFont = { ...newFont, key, filename: `${key}.${fontExtension}`, isNew: true };
    if (fonts.some(font => font.filename === finalNewFont.filename)) {
      setFonts(fonts.map(font => (font.filename === finalNewFont.filename ? finalNewFont : font)));
    } else {
      setFonts([...fonts, finalNewFont]);
    }
  };

  return (
    <Dialog fullWidth fullScreen open aria-labelledby="fonts-dialog-title">
      <DialogTitle id="fonts-dialog-title">Fonts</DialogTitle>
      <DialogContent dividers>
        {(!fonts || !fonts.length) && <div>You have not added any fonts for this template.</div>}
        {!!fonts && fonts.length > 0 && fonts.map(font => (<div>{font.family}</div>))}
        {showNewFontDialog && (
          <Dialog open aria-labelledby="new-font-dialog-title">
            <DialogTitle id="new-font-dialog-title">Add New Font</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <TextField
                  label="Font-Family"
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={event => setNewFont({ ...newFont, family: event.target.value })}
                />
                <FormHelperText>This is what you reference in CSS font-family property</FormHelperText>
              </FormControl>
              <FormControl>
                <input
                  id="new-font-upload-input"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={event => setNewFontSource(event.target.files[0])}
                />
                <label htmlFor="new-font-upload-input">
                  <Button color="secondary" aria-label="upload font" component="span"><AddIcon /> Upload Font</Button>
                </label>
              </FormControl>
              <div>{errorMessage}</div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => saveFont()}>Save Font</Button>
              <Button color="primary" onClick={() => setShowNewFontDialog(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </DialogContent>
      <DialogActions>
        {isEditable && <Button color="secondary" onClick={() => setShowNewFontDialog(true)}>Add New Font</Button>}
        <Button color="primary" onClick={() => setShowFonts(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

FontsDialog.propTypes = {
  fonts: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditable: PropTypes.bool.isRequired,
  setFonts: PropTypes.func.isRequired,
  setShowFonts: PropTypes.func.isRequired,
};

export default FontsDialog;
