import axios from 'axios';
import { getHybridToken } from '../services/hybridAuthService';
import BASE_URLS from '../constants/baseUrls';
import getEnv from './getEnv';

const onError = (error, authService) => {
  const errorStatus = error && error.response && error.response.status;
  if (errorStatus === 401) {
    localStorage.removeItem('hybridToken');
    authService.logout();
  }
  return Promise.reject(error);
};

export default (authService) => {
  axios.interceptors.response.use(undefined, error => onError(error, authService));
  axios.interceptors.request.use(async (config) => {
    const nonHybridTokenUrl = config.url.indexOf(BASE_URLS[getEnv()].hybridAuth) !== -1
      || config.url.indexOf('amazonaws') !== -1;
    if (nonHybridTokenUrl) {
      return config;
    }

    const hybridToken = await getHybridToken();
    if (!hybridToken) {
      return config;
    }

    const authorizedConfig = { ...config };
    authorizedConfig.headers.Authorization = `Bearer ${hybridToken}`;
    authorizedConfig.headers['Content-Type'] = 'application/json';
    return authorizedConfig;
  }, err => Promise.reject(err));
};
