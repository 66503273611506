import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/appStyles';
import languageMap from '../../constants/languages';

const CopyEditDialog = ({
  classes,
  i18nBundle,
  isEditable,
  languages,
  removeLanguage,
  setCopy,
  setShowAddLanguageDialog,
  setShowCopy,
  textEditDialogClick,
}) => {
  const i18nBundleKeys = (i18nBundle && i18nBundle.en && Object.keys(i18nBundle.en)) || [];

  return (
    <Dialog fullWidth fullScreen open aria-labelledby="copy-dialog-title">
      <DialogTitle id="copy-dialog-title">Copy</DialogTitle>
      <DialogContent dividers>
        {languages.map(language => (
          <div key={`${language}-copy-section`}>
            <h3>
              {languageMap[language]}
              {language !== 'en' &&
                <Button color="secondary" className={classes.smallButton} onClick={() => removeLanguage(language)}>Remove Language</Button>
              }
            </h3>
            {i18nBundleKeys.map(variableName => (
              <FormControl key={`${variableName}-form-control`}>
                <TextField
                  label={variableName}
                  size="small"
                  variant="outlined"
                  disabled={!isEditable}
                  style={{ margin: 10 }}
                  value={i18nBundle[language][variableName]}
                  onChange={event => setCopy(variableName, language, event.target.value)}
                />
                <FormHelperText style={{ textAlign: 'right' }}>
                  <Button
                    color="secondary"
                    className={classes.smallButton}
                    style={{ marginTop: -12 }}
                    onClick={() => textEditDialogClick(variableName, true, language)}
                  >
                    Expand
                  </Button>
                </FormHelperText>
              </FormControl>
            ))}
          </div>
        ))}
        {Object.keys(languageMap).some(lang => !languages.includes(lang)) &&
          <Button color="secondary" className={classes.smallButton} onClick={() => setShowAddLanguageDialog(true)}>Add Language</Button>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setShowCopy(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

CopyEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  i18nBundle: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeLanguage: PropTypes.func.isRequired,
  setCopy: PropTypes.func.isRequired,
  setShowAddLanguageDialog: PropTypes.func.isRequired,
  setShowCopy: PropTypes.func.isRequired,
  textEditDialogClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(CopyEditDialog);
