import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

const NewTemplateDialog = ({ closeDialog, createTemplate }) => {
  const [newTemplate, setNewTemplate] = useState({ templateName: '' });
  const [hasNewTemplateFormError, setHasNewTemplateFormError] = useState(false);

  const updateNewTemplate = (field, value) => {
    const validNameRegexp = /^[a-z0-9\-_ ]+$/i;
    if (!validNameRegexp.test(value)) {
      setHasNewTemplateFormError(true);
    } else {
      setHasNewTemplateFormError(false);
    }
    const updatedTemplate = { ...newTemplate, [field]: value };
    setNewTemplate(updatedTemplate);
  };

  const createTemplateClick = () => {
    if (!hasNewTemplateFormError) {
      const finalTemplate = {
        ...newTemplate,
        templateKey: newTemplate.templateName.replace(/\s+/g, '-').toLowerCase(),
      };

      createTemplate(finalTemplate);
    }
  };

  return (
    <Dialog open aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Template</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          error={hasNewTemplateFormError}
          helperText="Name can only have letters, numbers, spaces, dashes and underscores"
          margin="dense"
          id="name"
          label="Template Name"
          type="text"
          value={newTemplate.templateName}
          onChange={event => updateNewTemplate('templateName', event.target.value)}
          fullWidth
        />
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel shrink>
            Template Type
          </InputLabel>
          <Select
            labelId="template-type-select"
            id="template-type-select"
            defaultValue="ejs"
            fullWidth
            onChange={event => updateNewTemplate('templateType', event.target.value)}
          >
            <MenuItem value="ejs">EJS</MenuItem>
            <MenuItem value="pdf">PDF</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Cancel</Button>
        <Button onClick={createTemplateClick} color="primary">Create</Button>
      </DialogActions>
    </Dialog>
  );
};

NewTemplateDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  createTemplate: PropTypes.func.isRequired,
};

export default NewTemplateDialog;
