import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Badge,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/appStyles';
import { secondary } from '../../styles/colors';

import GenericDialog from '../general/GenericDialog';

const StyledEditHeader = styled.div`
  padding: 20px;

  .header-first-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .tpl-name-and-version {
      display: flex;
      justify-content: start;

      h2 {
        padding: 0 20px 0 0;
      }

      .version-status-badge {
        margin: 24px;
      }
    }
  }

  .header-second-line {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .left-buttons {
      display: flex;
      justify-content: start;
    }
  }
`;

const EditHeader = ({
  classes,
  cloneVersion,
  hasChanges,
  publishVersion,
  saveVersion,
  switchVersion,
  template,
  testVersion,
  versions,
}) => {
  const history = useHistory();
  const [selectedVersion, setSelectedVersion] = useState(template.version);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [warningCtaText, setWarningCtaText] = useState('');
  const [warningContinueFunc, setWarningContinueFunc] = useState(null);

  const backToListClick = (shouldContinue) => {
    if (hasChanges && !shouldContinue) {
      setWarningMessage('You have unsaved changes. Are you sure you want to go back to the list view?');
      setWarningCtaText('Back to List');
      setWarningContinueFunc(() => backToListClick);
      setShowWarning(true);
    } else {
      history.push('/');
    }
  };

  const publishVersionClick = (shouldContinue) => {
    if (hasChanges && !shouldContinue) {
      setWarningMessage('You have unsaved changes. It is recommended that you save and test all changes prior to publishing.');
      setWarningCtaText('Discard Changes and Publish');
      setWarningContinueFunc(() => publishVersionClick);
      setShowWarning(true);
    } else {
      publishVersion();
    }
  };

  const testVersionClick = (shouldContinue) => {
    if (hasChanges && !shouldContinue) {
      setWarningMessage('You have unsaved changes. If you want to test these changes, close this dialog and first save your changes.');
      setWarningCtaText('Test Anyway');
      setWarningContinueFunc(() => testVersionClick);
      setShowWarning(true);
    } else {
      testVersion();
    }
  };

  const switchVersionClick = (shouldContinue, version) => {
    if (version && version.version === template.version) {
      return;
    }

    const versionToSwitchTo = version || versions.find(v => v.version === selectedVersion);
    if (hasChanges && !shouldContinue) {
      setSelectedVersion(version.version);
      setWarningMessage('You have unsaved changes. If you want to save these changes, close this dialog and first save your changes.');
      setWarningCtaText('Discard Changes and Switch');
      setWarningContinueFunc(() => switchVersionClick);
      setShowWarning(true);
    } else {
      switchVersion(versionToSwitchTo);
    }
  };

  const clearWarning = () => {
    setShowWarning(false);
    setWarningMessage('');
    setWarningCtaText('');
    setWarningContinueFunc(null);
  };

  return (
    <div>
      <StyledEditHeader>
        <div className="header-first-line">
          <div className="tpl-name-and-version">
            <h2>{template.templateName}</h2>
            <FormControl>
              <InputLabel shrink>Version</InputLabel>
              <Select
                labelId="template-version"
                id="template-version"
                value={template.version}
                onChange={event => switchVersionClick(false, versions.find(version => version.version === event.target.value))}
              >
                {versions.map(version => (
                  <MenuItem value={version.version} key={`version-select-${version.version}`}>
                    {version.version}
                    {!!version.isActive && version.isPublished && ' (Active/Published)'}
                    {!!version.isActive && !version.isPublished && ' (Active)'}
                    {!version.isActive && version.isPublished && ' (Published)'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="version-status-badge">
              {!!template.isActive && <Badge color="secondary" variant="dot">Active</Badge>}
              {!template.isActive && <Badge classes={{ badge: classes.inactiveBadge }} variant="dot">Inactive</Badge>}
            </div>
            <div className="version-status-badge">
              {template.isPublished && <Badge color="secondary" variant="dot">Published</Badge>}
              {!template.isPublished && <Badge classes={{ badge: classes.inactiveBadge }} variant="dot">Unpublished</Badge>}
            </div>
          </div>
          <Button color="primary" className={classes.smallButton} onClick={() => backToListClick()}>Back to List</Button>
        </div>
        <div className="header-second-line">
          <div className="left-buttons">
            {!template.isPublished && <Button variant="contained" color="primary" onClick={saveVersion}>Save Version</Button>}
            {template.isPublished && <Button variant="contained" color="primary" onClick={cloneVersion}>Clone Version</Button>}
            <Button variant="contained" color="secondary" onClick={() => testVersionClick()} style={{ marginLeft: 20 }}>Test Version</Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            disabled={template.isPublished && template.isActive === 1}
            onClick={() => publishVersionClick()}
          >
            Publish to Active Template
          </Button>
        </div>
        {hasChanges && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open
            style={{ backgroundColor: secondary }}
          >
            <SnackbarContent message="You have unsaved changes" style={{ backgroundColor: secondary }} />
          </Snackbar>
        )}
      </StyledEditHeader>
      {showWarning && (
        <GenericDialog
          actionClick={() => warningContinueFunc(true)}
          actionText={warningCtaText}
          title="Wait!"
          message={warningMessage}
          cancelText="Cancel"
          closeDialog={clearWarning}
        />
      )}
    </div>
  );
};

EditHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  cloneVersion: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  publishVersion: PropTypes.func.isRequired,
  saveVersion: PropTypes.func.isRequired,
  switchVersion: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  testVersion: PropTypes.func.isRequired,
  versions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default withStyles(styles)(EditHeader);
